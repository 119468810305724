import * as React from "react";
import { Heading, Text, Image, Flex, Box } from "rebass";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import { Helmet } from "react-helmet";
import SiteFooter from "../components/SiteFooter";

import * as styles from "./Story.module.css";

import faleulaProfile from "../assets/images/faleula.png";
import faleulaImage from "../assets/images/faleula-image.jpg";
import storyIcon1 from "../assets/images/story-icon-1.png";
import storyIcon2 from "../assets/images/story-icon-2.png";
import storyIcon3 from "../assets/images/story-icon-3.png";
import HeroLayoutEmpty from "../components/HeroLayoutEmpty";

import HeroGridCard from "../components/HeroGridCard";
import HeroGrid from "../components/HeroGrid";

const FaleulaStoryPage = () => {
  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Skodel - Faleula's Story</title>
            <meta property="og:title" content="Skodel - Faleula's Story" />
            <meta
              property="og:description"
              content="Read Fauela's story, how Skodel supported her students and why she is passionate about this area"
            />
          </Helmet>
          <HeroGridCard
            className={styles.storyFaleulaContainer}
            color="white"
            style={{ maxWidth: "unset" }}
          >
            <Box className={styles.heroStoryFaleulaStripes}>
              <Box className={styles.heroStoryFaleulaStripesInner}>
                <Box className={styles.heroStoryFaleulaStripe1} />
                <Box className={styles.heroStoryFaleulaStripe2} />
                <Box className={styles.heroStoryFaleulaStripe3} />
              </Box>
            </Box>
            <HeroLayoutEmpty className={styles.storyFaleulaWrapper}>
              <Flex justifyContent="center">
                <Box className={styles.storyFaleulaContent}>
                  <Box className={styles.storyFaleulaImage}>
                    <Image
                      src={faleulaProfile}
                      alt="A photo of Faleula Galeai Aoelua Sappa"
                    />
                  </Box>
                  <Heading className={styles.storyFaleulaName}>
                    Faleula Galeai Aoelua Sappa
                  </Heading>
                  <Heading className={styles.storyFaleulaOccupation}>
                    Teacher
                  </Heading>
                  <Heading className={styles.storyFaleulaOccupationBig}>
                    Key Takeaway from Skodel: Students felt appreciated and
                    cared for
                  </Heading>
                  <Text className={styles.storyFaleulaMessage}>
                    Introducing Faleula Galeai Aoelua Sappa. Before sharing
                    Faleula’s story, Skodel would like to give thanks to the
                    work she does for her students in a significantly
                    challenging environment. Many of Faleula’s students come
                    from broken homes, speak little English and have little
                    access to the internet. So much is her commitment that she
                    has her students sit by her side and one by one she carries
                    out their check-ins, translating for them when necessary.
                  </Text>
                  <Box className={styles.storySparator}>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon1} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        What inspired you to become a teacher?
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        After I graduated from college, my aunt who was the
                        principal then asked if I would teach at her school. I
                        had a degree which was all I needed to teach. I wanted
                        to help support my parents so I took the job!
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon2} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Describe one moment that made you realise how special it
                        is to be a teacher.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        My students wanted to participate in the National
                        History Day because if you win the island-wide
                        competition, you fly to the other side of the world to
                        compete nationwide. It was a dream for them. I believed
                        in them. I studied the rules and regulations and helped
                        the students know what they needed to prepare. It took a
                        lot of research at the library and online and asking
                        questions to the elderly who were familiar with that
                        part of our local history, writing and rewriting,
                        practice before school, during lunch and after school
                        and weekends. Parents weren’t there physically but they
                        permitted their children to come. I kept cheering for my
                        students. They designed their own costumes and
                        background and added cultural music to make it look more
                        real. They won not only the school competition but the
                        island-wide as well. They did not win nationwide because
                        English is their second language but I was so proud of
                        them. They needed someone to believe in them and I did.
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon3} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Do you have a Skodel story for us? A moment where Skodel
                        has helped you make a difference in a student/s life.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        My students are juveniles. They come from broken homes
                        and receive little or no support from families or
                        friends. Rarely do they get the opportunity to use a
                        device. I teach English. They are all English Second
                        Language learners. I share my personal laptop with them
                        so they can participate in Skodel. Skodel motivates my
                        students to read, write, type and communicate. They work
                        on their own and at their own pace with Skodel. Skodel
                        makes them feel appreciated. Now they write to me and I
                        believe it’s because of Skodel. They feel they are
                        important individuals that can contribute to society!
                      </Text>
                    </Box>
                  </Box>
                  <Image
                    className={styles.storyImage}
                    src={faleulaImage}
                    alt=""
                  />
                  <Text className={styles.storyParagraph}>
                    Thank you, Faleula. There is something special about this
                    story. The process of learning a language and learning about
                    yourself neatly aligned side by side. A reminder to us all
                    that language is not just a tool for communication with
                    others, it is a tool to order our thoughts and express
                    ourselves, a tool to help us navigate our way in this world.
                  </Text>
                  <Text className={styles.storyNote}>
                    Note: Faleula's students have been released during the
                    island lockdown. They are without devices but she continues
                    to teach by sending weekly physical packages to each of her
                    students.
                  </Text>
                </Box>
              </Flex>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <SiteFooter />
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default FaleulaStoryPage;
